/* ---- respond BELOW ---- */
/* ---- respond ABOVE ---- */
/* ---- respond BETWEEN ---- */
@font-face {
  font-family: "SkodaNext-Regural";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/SKODANext-Regular.otf");
  src: ur("/fonts/SKODANext-Regular.ttf") format("truetype"), url("/fonts/SKODANext-Regular.eot"), url("/fonts/SKODANext-Regular.eot") format("embedded-opentype"), url("/fonts/SKODANext-Regular.woff") format("woff"), url("/fonts/SKODANext-Regular.woff2") format("woff2"), url("/fonts/SKODANext-Regular.svg") format("svg");
}
@font-face {
  font-family: "SkodaNext-Light";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/SKODANext-Light.otf");
  src: url("/fonts/SKODANext-Light.ttf") format("truetype"), url("/fonts/SKODANext-Light.eot"), url("/fonts/SKODANext-Light.eot") format("embedded-opentype"), url("/fonts/SKODANext-Light.woff") format("woff"), url("/fonts/SKODANext-Light.svg") format("svg");
}
@font-face {
  font-family: "SkodaNext-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/SKODANext-Bold.otf");
  src: url("/fonts/SKODANext-Bold.ttf") format("truetype"), url("/fonts/SKODANext-Bold.eot"), url("/fonts/SKODANext-Bold.eot") format("embedded-opentype"), url("/fonts/SKODANext-Bold.woff") format("woff"), url("/fonts/SKODANext-Bold.woff2") format("woff2"), url("/fonts/SKODANext-Bold.svg") format("svg");
}
@font-face {
  font-family: "SkodaNext-Black";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/SkodaNext-Black.otf");
  src: url("/fonts/SKODANext-Black.ttf") format("truetype"), url("/fonts/SKODANext-Black.eot"), url("/fonts/SKODANext-Black.eot") format("embedded-opentype"), url("/fonts/SKODANext-Black.woff") format("woff"), url("/fonts/SKODANext-Black.woff2") format("woff2"), url("/fonts/SKODANext-Black.svg") format("svg");
}
.font-light {
  font-family: "SkodaNext-Light";
}

.font-regural {
  font-family: "SkodaNext-Regural";
}

.font-bold, .safety-systems-section .group-block.active .group-block-header, .safety-systems-section .group-id {
  font-family: "SkodaNext-Bold";
}

.font-black-style, section .section-title, .btn-green {
  font-family: "SkodaNext-Black";
}

.font-black {
  color: black;
}

.font-green {
  color: #3aaa35;
}

.font-white {
  color: white;
}

.font10 {
  font-size: 10px;
  line-height: 12px;
}

.font12 {
  font-size: 12px;
  line-break: 14px;
}

.font14, .error-msg {
  font-size: 14px;
  line-height: 16px;
}
@media (max-width: 576px) {
  .font14, .error-msg {
    font-size: 12px;
    line-height: 14px;
  }
}

.font16, .custom-checkbox label {
  font-size: 16px;
  line-height: 18px;
}
@media (max-width: 576px) {
  .font16, .custom-checkbox label {
    font-size: 14px;
    line-height: 16px;
  }
}

.font18, .safety-systems-section .group-block .group-block-header {
  font-size: 18px;
  line-height: 20px;
}
@media (max-width: 576px) {
  .font18, .safety-systems-section .group-block .group-block-header {
    font-size: 16px;
    line-height: 18px;
  }
}

.font20 {
  font-size: 20px;
  line-height: 22px;
}
@media (max-width: 576px) {
  .font20 {
    font-size: 18px;
    line-height: 20px;
  }
}

.font22 {
  font-size: 22px;
  line-height: 30px;
}
@media (max-width: 576px) {
  .font22 {
    font-size: 20px;
    line-height: 28px;
  }
}

.font24 {
  font-size: 24px;
  line-height: 26px;
}
@media (max-width: 576px) {
  .font24 {
    font-size: 22px;
    line-height: 24px;
  }
}

.font26 {
  font-size: 26px;
  line-height: 32px;
}
@media (max-width: 576px) {
  .font26 {
    font-size: 24px;
    line-height: 30px;
  }
}

.font32 {
  font-size: 32px;
  line-height: 38px;
}
@media (max-width: 576px) {
  .font32 {
    font-size: 22px;
    line-height: 28px;
  }
}

.font34 {
  font-size: 34px;
  line-height: 40px;
}
@media (max-width: 576px) {
  .font34 {
    font-size: 24px;
    line-height: 30px;
  }
}

.font36 {
  font-size: 36px;
  line-height: 42px;
}
@media (max-width: 576px) {
  .font36 {
    font-size: 26px;
    line-height: 32px;
  }
}

.font40 {
  font-size: 40px;
  line-height: 46px;
}
@media (max-width: 576px) {
  .font40 {
    font-size: 30px;
    line-height: 36px;
  }
}

.font42 {
  font-size: 42px;
  line-height: 58px;
}
@media (max-width: 576px) {
  .font42 {
    font-size: 40px;
    line-height: 56px;
  }
}

.font72 {
  font-size: 72px;
  line-height: 78px;
}
@media (max-width: 576px) {
  .font72 {
    font-size: 62px;
    line-height: 68px;
  }
}

/* ---- respond BELOW ---- */
/* ---- respond ABOVE ---- */
/* ---- respond BETWEEN ---- */
*,
p,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

[class*=col-] {
  padding: 0;
  margin: 0;
}

::placeholder {
  color: #bbbbbb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbbbbb !important;
}

::-ms-input-placeholder {
  color: #bbbbbb !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

body {
  min-width: 32rem;
  font: 1.6rem/2rem "SkodaNext-Regural";
  background-color: white;
  color: black;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}

h2 {
  font: 3.2rem/4.8rem "SkodaNext-Black";
  color: black;
}
@media (max-width: 991px) {
  h2 {
    font: 2.2rem/2.6rem "SkodaNext-Bold";
  }
}

h3 {
  font: 20px/24px "SkodaNext-Bold";
}
@media (max-width: 991px) {
  h3 {
    font: 1.6rem/1.8rem "SkodaNext-Bold";
  }
}

p {
  font: 1.8rem/2.6rem "SkodaNext-Light";
}
@media (max-width: 991px) {
  p {
    font: 1.6rem/2.2rem "SkodaNext-Light";
  }
}

.container-content {
  max-width: 117rem;
  margin-left: auto;
  margin-right: auto;
}

.btn-white {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 56px;
  width: 180px;
  color: black;
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  outline: none;
  border: 1px solid black;
  border-radius: 72px;
}
.btn-white:hover {
  color: black;
  box-shadow: 0 2px 16px 0 rgba(255, 255, 255, 0.6);
  text-decoration: none;
}
.btn-white:hover a {
  color: black;
  text-decoration: none;
}
.btn-white:focus {
  outline: none;
}
.btn-white:hover {
  box-shadow: 0 2px 16px 0 rgba(187, 187, 187, 0.6);
}

.btn-green {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3aaa35;
  height: 56px;
  width: 180px;
  color: white;
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  outline: none;
  border: 1px solid #3aaa35;
  border-radius: 72px;
}
.btn-green:hover {
  color: white;
  box-shadow: 0 2px 16px 0 rgba(58, 170, 53, 0.6);
  text-decoration: none;
}
.btn-green:hover a {
  color: white;
  text-decoration: none;
}
.btn-green:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-checkbox label {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  padding-left: 36px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 991px) {
  .custom-checkbox label {
    margin: 0;
  }
}
@media (min-width: 991px) {
  .custom-checkbox label {
    padding-left: 3vw;
  }
}
.custom-checkbox label input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}
.custom-checkbox span {
  height: 24px;
  width: 24px;
  background-color: white;
  color: transparent;
  border-radius: 0;
  border: 1px solid black;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.1s background-color;
  text-align: center;
}
.custom-checkbox span:focus, .custom-checkbox span.ng-invalid:focus {
  outline-width: 0 !important;
  box-shadow: none;
  color: transparent;
  border-color: #3aaa35;
}
.custom-checkbox span.ng-dirty.ng-invalid:not(.readonly), .custom-checkbox span.ng-touched.ng-invalid:not(.readonly), .custom-checkbox span.invalid {
  border-color: #f44336;
}
.custom-checkbox span.invalid {
  border: 1px solid #f44336;
  border-radius: 0px;
}
.custom-checkbox .checkmark.invalid {
  border: 1px solid #f44336;
  border-radius: 0px;
}
.custom-checkbox label input:checked ~ span {
  border-color: #3aaa35;
  background-color: #3aaa35;
}
.custom-checkbox label input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 25%;
  top: 25%;
  height: 1rem;
  width: 1rem;
  background-image: url("../assets/images/icons/check_field_icon.svg");
}

.custom-dropdown {
  position: relative;
}
.custom-dropdown .dropdown-menu {
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
  border-bottom: 0;
}
@media (max-width: 576px) {
  .custom-dropdown .dropdown-menu {
    position: unset;
    max-height: 50vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.custom-dropdown .dropdown-menu .dropdown-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  transition: 0.2s background-color;
  border-bottom: 1px solid #bbbbbb;
  cursor: pointer;
  outline: none;
}
.custom-dropdown .dropdown-menu .dropdown-item:hover {
  box-shadow: inset 0 0 10px #bbbbbb;
}
.custom-dropdown .dropdown-menu .dropdown-item img {
  width: 30px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.error-msg {
  text-align: right;
  color: red;
  margin-top: 5px;
}
.error-msg img {
  width: 10px;
}

.container-padding-x {
  padding: 0 30px;
}
@media (min-width: 991px) {
  .container-padding-x {
    padding: 0 4vw;
  }
}
@media (min-width: 1440px) {
  .container-padding-x {
    padding: 0 6vw;
  }
}
@media (min-width: 1920px) {
  .container-padding-x {
    padding: 0 10vw;
  }
}

.container-padding-y {
  padding: 40px 0;
}
@media (min-width: 991px) {
  .container-padding-y {
    padding: 82px 0;
  }
}

.container-padding-xy, section .section-title {
  padding: 40px 30px;
}
@media (min-width: 991px) {
  .container-padding-xy, section .section-title {
    padding: 82px 4vw;
  }
}
@media (min-width: 1440px) {
  .container-padding-xy, section .section-title {
    padding: 0 6vw;
  }
}
@media (min-width: 1920px) {
  .container-padding-xy, section .section-title {
    padding: 82px 10vw;
  }
}

section {
  text-align: center;
}
@media (max-width: 991px) {
  section {
    text-align: left;
  }
}
img {
  max-width: 100%;
  height: auto;
}

a,
a:hover {
  text-decoration: none;
}

.white-shadow-container {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 0.5rem 3rem #00000014;
  padding: 2.5rem 3rem;
}

.safety-systems-section .carousel-nav .carousel-arrows {
  margin-top: 2rem;
  background-color: transparent;
}
.safety-systems-section .carousel-nav .carousel-arrows .arrow {
  display: inline-block;
  width: 4.5rem;
  height: 4.5rem;
  background-color: #3aaa35;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 0.5rem;
  cursor: pointer;
}
.safety-systems-section .carousel-nav .carousel-arrows .arrow.prev {
  background-image: url("../assets/images/icons/carousel-arrow-left.png");
}
.safety-systems-section .carousel-nav .carousel-arrows .arrow.next {
  background-image: url("../assets/images/icons/carousel-arrow-right.png");
}
.safety-systems-section .group-id {
  color: rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}
.safety-systems-section .group-block {
  margin: 2rem 0;
}
.safety-systems-section .group-block .group-block-header {
  display: flex;
  align-items: flex-start;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  z-index: 100;
}
.safety-systems-section .group-block .group-block-header > div:first-child {
  min-width: 9rem;
  position: relative;
  text-align: center;
}
.safety-systems-section .group-block .group-block-header > div:first-child::before {
  content: "";
  width: 1rem;
  height: 100%;
  display: block;
  background-image: url(../assets/images/icons/toogle-icon-black.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  transition: transform 0.5s;
}
.safety-systems-section .group-block .group-block-content {
  background-color: white;
}
.safety-systems-section .group-block.active .group-block-header {
  color: #3aaa35;
}
.safety-systems-section .group-block.active .group-block-header > div:first-child {
  min-width: 9rem;
  position: relative;
  text-align: center;
}
.safety-systems-section .group-block.active .group-block-header > div:first-child::before {
  background-image: url(../assets/images/icons/toogle-icon-green.png);
  transform: rotate(-180deg);
}
.safety-systems-section .carousel .slide {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}