@import 'variables';

/* ---- respond BELOW ---- */
@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (max-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

/* ---- respond ABOVE ---- */
@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

/* ---- respond BETWEEN ---- */
@mixin respond-between($lower, $upper) {
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
    {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper)-1;

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
            @content;
        }
    } @else {
        @if (map-has-key($breakpoints, $lower) == false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }
        @if (map-has-key($breakpoints, $upper) == false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

@mixin btn-basic(
    $height: 64px,
    $width: 210px,
    $background: transparent,
    $color: $white
) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    height: $height;
    width: $width;
    color: $color;
    transition: box-shadow 0.2s ease;
    cursor: pointer;
    outline: none;

    &:hover {
        color: $color;
        box-shadow: 0 2px 16px 0 rgba($background, $alpha: 0.6);
        text-decoration: none;

        a {
            color: $color;
            text-decoration: none;
        }
    }

    &:focus {
        outline: none;
    }
}

@mixin input-basic(
    $height: 56px,
    $width: 100%,
    $background: transparent,
    $color: $black
) {
    height: $height;
    width: $width;
    background-color: $background;
    color: $color;
    border-radius: 0;
    @include basic-border($radius: 4px);

    &:focus,
    &.ng-invalid:focus {
        outline-width: 0 !important;
        box-shadow: none;
        color: $color;
        border-color: $green;
    }

    &.ng-dirty.ng-invalid:not(.readonly),
    &.ng-touched.ng-invalid:not(.readonly),
    &.invalid {
        border-color: $red;
    }
}

@mixin basic-border($width: 1px, $color: $black, $radius: 0px) {
    border: $width solid $color;
    border-radius: $radius;
}

@mixin font-size($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height;

    @include respond-below(xs) {
        font-size: $font-size - 2;
        line-height: $line-height - 2;
    }
}

@mixin bigger-font-size($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height;

    @include respond-below(xs) {
        font-size: $font-size - 10;
        line-height: $line-height - 10;
    }
}
