$breakpoints: (
  xxs: 480px,
  xs: 576px,
  sx: 676px,
  sm: 768px,
  md: 991px,
  w1170: 1170px,
  lg: 1260px,
  w1390: 1390px,
  w1440: 1440px,
  w1500: 1500px,
  xl: 1920px,
);

$white: rgba(255, 255, 255, 1);
$white-2: rgba(250, 250, 250, 1);
$white-3: rgba(237, 237, 237, 1);
$black: rgba(0, 0, 0, 1);
$black-alfa-1: rgba(0, 0, 0, 0.08);
$black-alfa-2: rgba(0, 0, 0, 0.39);
$black-light: rgba(39, 39, 39, 1);
$black-light-alfa: rgba(39, 39, 39, .9);
$gray: rgba(119, 119, 119, 1);
$gray-2: rgba(187, 187, 187, 1);
$red: rgba(244, 67, 54, 1);
$green: rgba(58, 170, 53, 1);

%flex-centerY {
  display: flex;
  align-items: center;
}

%flex-centerX {
  display: flex;
  justify-content: center;
}

%flex-centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-space-Bet {
  display: flex;
  justify-content: space-between;
}
