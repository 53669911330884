@import 'variables';
@import 'mixins';

@font-face {
  font-family: 'SkodaNext-Regural';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SKODANext-Regular.otf');
  src: ur('/fonts/SKODANext-Regular.ttf') format('truetype'),
    url('/fonts/SKODANext-Regular.eot'),
    url('/fonts/SKODANext-Regular.eot') format('embedded-opentype'),
    url('/fonts/SKODANext-Regular.woff') format('woff'),
    url('/fonts/SKODANext-Regular.woff2') format('woff2'),
    url('/fonts/SKODANext-Regular.svg') format('svg');
}

@font-face {
  font-family: 'SkodaNext-Light';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SKODANext-Light.otf');
  src: url('/fonts/SKODANext-Light.ttf') format('truetype'),
    url('/fonts/SKODANext-Light.eot'),
    url('/fonts/SKODANext-Light.eot') format('embedded-opentype'),
    url('/fonts/SKODANext-Light.woff') format('woff'),
    url('/fonts/SKODANext-Light.svg') format('svg');
}

@font-face {
  font-family: 'SkodaNext-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/SKODANext-Bold.otf');
  src: url('/fonts/SKODANext-Bold.ttf') format('truetype'),
    url('/fonts/SKODANext-Bold.eot'),
    url('/fonts/SKODANext-Bold.eot') format('embedded-opentype'),
    url('/fonts/SKODANext-Bold.woff') format('woff'),
    url('/fonts/SKODANext-Bold.woff2') format('woff2'),
    url('/fonts/SKODANext-Bold.svg') format('svg');
}

@font-face {
  font-family: 'SkodaNext-Black';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/SkodaNext-Black.otf');
  src: url('/fonts/SKODANext-Black.ttf') format('truetype'),
    url('/fonts/SKODANext-Black.eot'),
    url('/fonts/SKODANext-Black.eot') format('embedded-opentype'),
    url('/fonts/SKODANext-Black.woff') format('woff'),
    url('/fonts/SKODANext-Black.woff2') format('woff2'),
    url('/fonts/SKODANext-Black.svg') format('svg');
}

.font-light {
  font-family: 'SkodaNext-Light';
}

.font-regural {
  font-family: 'SkodaNext-Regural';
}

.font-bold {
  font-family: 'SkodaNext-Bold';
}

.font-black-style {
  font-family: 'SkodaNext-Black';
}

.font-black {
  color: $black;
}

.font-green {
  color: $green;
}

.font-white {
  color: $white;
}

.font10 {
  font-size: 10px;
  line-height: 12px;
}

.font12 {
  font-size: 12px;
  line-break: 14px;
}

.font14 {
  @include font-size(14px, 16px);
}

.font16 {
  @include font-size(16px, 18px);
}

.font18 {
  @include font-size(18px, 20px);
}

.font20 {
  @include font-size(20px, 22px);
}

.font22 {
  @include font-size(22px, 30px);
}

.font24 {
  @include font-size(24px, 26px);
}

.font26 {
  @include font-size(26px, 32px);
}

.font32 {
  @include bigger-font-size(32px, 38px);
}

.font34 {
  @include bigger-font-size(34px, 40px);
}

.font36 {
  @include bigger-font-size(36px, 42px);
}

.font40 {
  @include bigger-font-size(40px, 46px);
}

.font42 {
    @include font-size(42px, 58px);
}

.font72 {
    @include bigger-font-size(72px, 78px);
}
